document.addEventListener('mousedown', e => {
  if (e.target.nodeName === 'IMG' && (e.which === 3 || e.button === 2)) {
    e.preventDefault();
    return false;
  }
});

window.oncontextmenu = e => {
  e.preventDefault();
};
